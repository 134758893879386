<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <!-- :loading="loading" -->
        <!-- v-model="selected" -->
        <v-data-table
          :options.sync="options"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="items"
          item-key="student_id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Certificates") }}
                </h3>
              </v-col>
              <v-col md="2" sm="12">
                <p class="mt-2 text-right">Semester/Quarter</p>
              </v-col>
              <v-col md="2" sm="12">
                <v-autocomplete
                  :label="$t('Quarters')"
                  :items="quarters"
                  item-text="name"
                  item-value="id"
                  v-model="selectedQuarter"
                  solo
                ></v-autocomplete>
              </v-col>

              <v-col md="3" sm="12" class="text-right"> </v-col>
            </v-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              big
              v-if="item.certificate"
              class="main-color"
              @click="dowenload(item.certificate)"
              :title="$t('Download Certificate')"
              style="
                background-color: transparent;
                box-shadow: none !important;
                text-decoration: underline;
              "
            >
              Download
            </v-btn>
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import ACL from "../../acl";
export default {
  name: "Certificates",

  data() {
    return {
      options: {
        itemsPerPage: -1,
      },
      headers: [
        {
          text: this.$i18n.t("Code"),
          value: "code",
          sortable: true,
        },
        {
          text: this.$i18n.t("Name"),
          value: "name",
          sortable: true,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      // fileObject: "",
      title: this.$i18n.t("Certificates"),
      itemObject: {},
      items: [],

      selectedQuarter: "",
    };
  },
  watch: {
    selectedQuarter: {
      handler() {
        if (this.selectedQuarter != "") {
          this.getAllData();
        }
      },
      deep: true,
    },
  },
  methods: {
    getQuarters() {
      axios
        .get(this.getApiUrl + "/exams/filter/getQuartersForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.quarters = response.data.data.quarters;

          //  this.filter.quarters =[];
          this.selectedQuarter = response.data.data.currntQuarterid;
        });
    },

    getAllData() {
      if (this.selectedQuarter != "") {
        axios
          .get(
            this.getApiUrl +
              "/performance/getCertificates/" +
              this.selectedQuarter,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.items = [];
            this.items = response.data.data;
            this.loading = false;
          });
      }
    },

    dowenload(cirtificate) {
      window.open(cirtificate, "_blank");
    },
  },
  mounted() {
    this.getQuarters();
    // this.getGrades();
    // this.getClasses();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

#wrapper {
  transition: all 0.4s ease 0s;
}

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

.select {
  margin-top: 20px !important;
}
</style>
